import React from 'react';
import clsx from 'clsx';
import { AppBrandConfigs } from 'AppBranding';
import { type ProgramType } from 'Program';
import { useTranslation } from 'react-i18next';
import { type InstitutionId } from 'Institutions/Institutions.types';
import FrontRoyalSpinner from 'FrontRoyalSpinner';
import { type ChooseInstitutionConfig } from '../ChooseInstitution.types';

type InstitutionItemProps = {
    config: ChooseInstitutionConfig;
    onApplyClick: (programType: ProgramType, institutionId: InstitutionId) => void;
    loadingInstitutionId: InstitutionId | null;
};

const classes = {
    container: clsx([
        'tw-flex',
        'tw-w-full',
        'tw-max-w-[340px]',
        'tw-flex-col',
        'tw-items-center',
        'tw-rounded',
        'tw-p-[20px]',
        'sm:tw-p-[30px]',
    ]),
    logo: {
        container: clsx(['tw-mb-[16px]', 'tw-flex', 'tw-items-end', 'tw-justify-center', 'tw-h-[40px]']),
    },
    heading: clsx(['tw-mb-[15px]', 'tw-text-center', 'tw-text-[15px]', 'tw-font-semibold', 'tw-text-white']),
    description: {
        container: clsx(['tw-mb-[20px]', 'tw-flex', 'tw-flex-col', 'tw-justify-between', 'tw-gap-[22px]']),
        paragraph: clsx(['tw-text-center', 'tw-text-[15px]', 'tw-leading-[22px]', 'tw-text-white']),
    },
    applyButton: clsx([
        'tw-flex',
        'tw-h-[35px]',
        'tw-w-[203px]',
        'tw-items-center',
        'tw-justify-center',
        'tw-rounded-[50px]',
        'tw-bg-white',
        'tw-text-[15px]',
        'tw-font-semibold',
        'tw-text-black',
        'disabled:tw-opacity-80',
        'disabled:tw-cursor-not-allowed',
    ]),
};

export function InstitutionItem({ config, onApplyClick, loadingInstitutionId }: InstitutionItemProps) {
    const { t } = useTranslation('back_royal');

    const itemIsLoading = loadingInstitutionId === config.institutionId;

    return (
        <div className={clsx(classes.container, config.containerClassnames)}>
            <h3 className="tw-sr-only">{AppBrandConfigs[config.institutionId].brandNameStandard}</h3>
            <div className={classes.logo.container}>
                <img
                    className={clsx(config.logoClassnames)}
                    src={config.logo}
                    alt={`${AppBrandConfigs[config.institutionId].brandNameStandard} logo`}
                />
            </div>
            <h4 className={classes.heading}>
                {t(config.degreeHeadingLocale.key, config.degreeHeadingLocale.params ?? {})}
            </h4>
            <div className={classes.description.container}>
                {config.descriptionParagraphs.map(paragraph => (
                    <p key={paragraph.key} className={classes.description.paragraph}>
                        {t(paragraph.key, paragraph.params ?? {})}
                    </p>
                ))}
            </div>
            <button
                type="button"
                data-testid="apply-button"
                className={classes.applyButton}
                onClick={() => onApplyClick(config.defaultProgramType, config.institutionId)}
                disabled={!!loadingInstitutionId}
            >
                {itemIsLoading ? (
                    <FrontRoyalSpinner className="no-delay no-top-margin tw-mt-[5px]" color={config.spinnerColor} />
                ) : (
                    t('choose_institution.choose_institution.apply_now')
                )}
            </button>
        </div>
    );
}
